import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsData } from '../../../actions/subscriptionAction';
import { getPageLists } from '../../../actions/cartAction';
import "../../../index.css";
import "./index.css";
import logo from "../../../images/new-design/site-logo.svg";
import mob from '../../../images/new-design/mob.svg';
import mail from '../../../images/new-design/mail.svg';
import loc from '../../../images/new-design/loc.svg';
import face from '../../../images/new-design/face-book.svg';
import twitter from '../../../images/new-design/tweet.svg';
import linkedin from '../../../images/new-design/in.svg';
import insta from '../../../images/new-design/insta.svg';
import apple from '../../../images/new-design/apple-store.svg';
import play from '../../../images/new-design/play-store.svg';
import WOW from '../../../js/wow';
import { includes } from 'lodash';

const Footer = (props) => {
    const settingsData = useSelector(state => state.subscriptionData.settingData),
        footerPageLists = useSelector(state => state.cartData.footerPageLists),
        dispatch = useDispatch();
    useEffect(() => {
        new WOW().init();
        dispatch(getSettingsData());
        dispatch(getPageLists());
    }, []);

    const go_to_pages = (e, url) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        (url === "/subscription-list") && sessionStorage.setItem("activeClass", 1);
        (url === "/features") && sessionStorage.setItem("activeClass", 2);
        (url === "/") && sessionStorage.setItem("activeClass", 4);
        (url === "/contact-us") && sessionStorage.setItem("activeClass", 3);
        props.history.push(url);
    }

    let footerText = '';
    if (settingsData && settingsData.footer_text && settingsData.footer_text !== undefined) {
        let textArr = String(settingsData.footer_text).split(',');
        let firstIndex = textArr[0].substring(0, textArr[0].length - 4);
        let dynamicYear = new Date().getFullYear();
        textArr.shift();
        footerText = firstIndex + dynamicYear + textArr;
    }

    const excludedPaths = [
        '/documents', '/dashboard', '/users', '/groups', '/subscriptions', '/users/add',
        '/users/edit', '/users/activity', '/saved-documents', '/create-new-documents',
        '/users/deleted-list', '/documents/compare', '/documents/editor', '/index',
        '/documents/recent-updates', '/documents/need-review', '/documents/uptodate',
        '/subscription/pay', '/settings', '/document/management/renewal', '/invoice',
        '/history', '/groups', '/group/add', '/group/edit', '/group/view', '/policies',
        '/policies/edit', '/policies/create', '/policies/multi/create', '/users/view',
        '/employees', '/employee/add', '/employee/edit', '/employee/view',
        '/deleted/employees', '/login', '/employee/login', '/employee/register',
        '/forgotpassword', '/verifyotp', '/resetpassword', '/myfiles', '/myfiles/editor',
        '/myfiles/details', '/policy/documents', '/policy/document/view',
        '/publishedpolicies', '/login/', '/deleted-documents', '/documents/sign/',"/myDocuments", "/myDocuments/editor",
        "/myDocuments/details",
    ];

    if (!props.location.pathname.startsWith('/reference/')  && !(props.location.pathname).startsWith("/document/sign")  && !(props.location.pathname).startsWith("/view/Document/") && !excludedPaths.includes(props.location.pathname)) {
        return (
            <section className="footer">
                <div className="container">
                    <div className="footer-sec">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 f-part">
                            <span className="foot-logo"><img src={logo} alt='logo' /></span>
                            <ul>
                                <li><span><img src={mob} alt='phone' /></span>{settingsData.phone ? (settingsData.phone).slice(0, 4) + ' ' + (settingsData.phone).slice(4, 7) + ' ' + (settingsData.phone).slice(7) : '0330 808 0050'}</li>
                                <li><span><img src={mail} alt='email' /></span><div>{settingsData.email_address ? settingsData.email_address : "info@company.com"}</div></li>
                                <li><span><img src={loc} alt='location' /></span><a href={'http://maps.google.com/maps?q=' + settingsData.address}>{settingsData.address}</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 f-part">
                            <h3>EXPLORE</h3>
                            <ul>
                                <li><div className="foo-explore" onClick={(e) => go_to_pages(e, "/policies-procedures")}>Policies &amp; Procedures</div></li>
                                <li><div className="foo-explore" onClick={(e) => go_to_pages(e, "/features")}>System Features</div></li>
                                <li><div className="foo-explore" onClick={(e) => go_to_pages(e, "/contact-us")}>Contact Us</div></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 f-part">
                            <h3>LEGAL</h3>
                            <ul>
                                {footerPageLists.length > 0 && footerPageLists.map((item, i) =>
                                    <li key={i}><Link to={{ pathname: `/pages/${item.slug}` }} onClick={() => sessionStorage.removeItem("activeClass")}>{item.name}</Link></li>
                                )}
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 f-part social-sec">
                            <h3>FOLLOW</h3>
                            <ul>
                                <li><a target="_blank" href={settingsData && settingsData.facebook_link && settingsData.facebook_link ? settingsData.facebook_link : "/"}><img src={face} /></a></li>
                                <li><a target="_blank" href={settingsData && settingsData.twitter_link && settingsData.twitter_link ? settingsData.twitter_link : "/"}><img src={twitter} /></a></li>
                                <li><a target="_blank" href={settingsData && settingsData.instagram_link && settingsData.instagram_link ? settingsData.instagram_link : "/"}><img src={insta} /></a></li>
                                <li><a target="_blank" href={settingsData && settingsData.pinterest_link && settingsData.pinterest_link ? settingsData.pinterest_link : "/"}><img src={linkedin} /></a></li>
                            </ul>
                            <h3>EMPLOYEE APP</h3>
                            <ul>
                                <li><a target="_blank" href= 'https://apps.apple.com/us/app/cloudoc/id6504866507'><img src={apple} style={{width:'24px',height:'24px'}}/></a></li>
                                <li><a target="_blank" href='https://play.google.com/store/apps/details?id=com.cloudoc'><img src={play} style={{width:'24px',height:'24px'}} /></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="f-bottom">
                    <div className="copy-w">{settingsData && settingsData !== undefined && settingsData.footer_text && settingsData.footer_text !== undefined && settingsData.footer_text !== null ? footerText : ` © ${new Date().getFullYear()} All rights reserved.`}</div>
                </div>
            </section>
        );
    } else {
        return null;
    }
};

export default Footer;