import * as actionTypes from '../actions/types.js';

export default (state = {
    list_of_folder_data: {}, listData: [], userSubscribedPlan: [],
    editViewData: {}, addViewData: {}, validationError: {}, paymentData: {}, subscriptionExpired: {},
    processingForm: true, settingData: {}, skip: 0, itemPerPage: 25, activePage: 1, count: 0, subscriptionLists: [],
    searchText: '', subscriptionDetails: {}, documentLists: [], d_skip: 0, d_itemPerPage: 25, d_count: 0, d_activePage: 1,
    package_price_filter: '', pricing_filter: '', files_count: 0, folder_data: [], file_path: [],
    activeTab: '1', Admin_json_list: [], docs_search: '', subscriptionView: 'list', subscriptionData: {},
    payable_deatails: {}, selectedPackage: [], selectedPackageDetails: {}, select_addon: [], planType: {},
    addedDocuments: [], addedDocType: '', planRenewalType: {},
    monthlymanagementCheck: 0, yearlymanagementCheck: 0, monthlyupdatesCheck: 0, yearlyupdatesCheck: 0, monthlycomboCheck: 0, yearlycomboCheck: 0, yearlyproCheck: 0, yearlyproCheck: 0,
    countryList: [], subscriptionTypeList: [], categoryList: [], showNoData: false, priceDetails: {}, packagePlanType: '', printingStatus: false,
    subscriptionDatas: {}, invoiceList: [], invoiceListCount: 0, invoiceDetails: {}, canUpdateSubscription: false, planSubscribedDate: [], filterValues: { skip: 0, limit: 10 },
    invoiceActivePage: 1, selectedEmpPlans: {}, viewDocument: '', selectedDocPlans: {}, selectedEmployeePlans: {}, subscriptionPlan: '1', showPlanModal: false, updatePlanType: '',
    showCancelsubscription: false, item_exist: {}, storageData: [], licenceType: 1, PlanPeriod: false, showUser: false, showAll: false
}, action) => {

    switch (action.type) {

        case actionTypes.SET_DOCS_SEARCH_VAL:
            return { ...state, docs_search: action.payload };

        case actionTypes.SET_ADMIN_JSON:
            return { ...state, Admin_json_list: [...action.payload] };

        case actionTypes.CLEAR_SUBS_DATA:
            return { ...state, subscriptionLists: [] };

        case actionTypes.SET_PRICINGFILTER:
            return { ...state, pricing_filter: action.payload };

        case actionTypes.SET_PACKAGEFILTER:
            return { ...state, package_price_filter: action.payload };

        case actionTypes.SET_HEART:
            return {
                ...state, subscriptionLists: state.subscriptionLists.map(e => {
                    let obj = {};
                    obj = e;
                    if (String(e._id) === String(action.payload)) {
                        obj.checked = true;
                    }
                    return obj;
                })
            }
        case actionTypes.SET_HEART_UNCHECKC:
            return {
                ...state, subscriptionLists: state.subscriptionLists.map(e => {
                    let obj = {};
                    obj = e;
                    if (String(e._id) === String(action.payload)) {
                        obj.checked = false;
                    }
                    return obj;
                })
            }
        case actionTypes.GET_FOLDERS_LIST:
            return {
                ...state,
                list_of_folder_data: { ...action.payload }
            };
        case actionTypes.SUBSCRIPTION_LIST:
            return {
                ...state,
                listData: [...action.payload],
                userSubscribedPlan: [...action.userSubscribedPlan],
                subscriptionExpired: { ...action.subscriptionExpired },
                planSubscribedDate: { ...state.planSubscribedDate, ...action.planSubscribedDate },
                // userSubscribedPlan: [...action.planSubscribedDate],
                // subscriptionExpired: { ...state.subscriptionExpired, ...action.subscriptionExpired },
                // listData: [...state.listData, ...action.payload],
            };

        case actionTypes.CLEAR_SUBSCRIPTION:
            return {
                ...state,
                listData: [...action.payload]
            };

        case actionTypes.SUBSCRIPTION_DELETE_MODAL:
            return {
                ...state,
                showSubscriptionDeleteModal: !state.showSubscriptionDeleteModal,
                deleteSubscriptionId: action.subscriptionId,
                listData: state.listData.filter(e => e._id !== action.subscriptionId)
            };

        case actionTypes.SUBSCRIPTION_EDIT_VIEW:
            return {
                ...state,
                editViewData: { ...state.editViewData, ...action.payload }
            };

        case actionTypes.SUBSCRIPTION_ADD_VIEW:
            return {
                ...state,
                addViewData: { ...state.addViewData, ...action.payload }
            };

        case actionTypes.PAYMENT_ADD_VIEW:
            return {
                ...state,
                paymentData: { ...state.paymentData, ...action.payload }
            };

        case actionTypes.CLEAR_SUBSCRIPTION_ADD_VIEW:
            return {
                ...state,
                addViewData: { ...action.payload }
            };

        case actionTypes.SHOW_SUBSCRIPTION:
            return {
                ...state,
                showSubscription: !state.showSubscription,
                renewalCycle: action.renewalCycle,
                paymentData: { ...action.payload },
                processingForm: { ...action.payload },
                subscriptedPlan: { ...state.subscriptedPlan, ...action.subscriptedPlan }
            };
        case actionTypes.SETTINGS_DATA:
            return {
                ...state,
                settingData: { ...state.settingData, ...action.payload }
            }
        case actionTypes.SUBSCRIPTION_LISTS_ATTRIBUTES:
            return {
                ...state,
                activePage: action.page_no,
                skip: action.skip
            }
        case actionTypes.SEARCH_SUBSCRIPTION_LISTS:
            return {
                ...state,
                subscriptionLists: [...action.payload],
                count: action.count
            }
        case actionTypes.SUBSCRIPTION_LISTS_SEARCHTEXT:
            return {
                ...state,
                searchText: action.searchText
            }
        case actionTypes.SUBSCRIPTION_DETAILS:
            return {
                ...state,
                subscriptionDetails: action.payload,
                documentLists: action.documents,
                d_count: action.count,
                files_count: action.files_count,
                folder_data: action.folder_data,
                file_path: action.file_path
            }
        case actionTypes.SUBSCRIPTION_DETAILS_ATTRIBUTES:
            return {
                ...state,
                d_skip: action.skip,
                d_activePage: action.page_no
            }
        case actionTypes.SUBSCRIPTION_DESCRIPTION_MODAL:
            return {
                ...state,
                showDescriptionModal: !state.showDescriptionModal,
                subscriptionDescription: action.payload
            }
        case actionTypes.SET_TABS_FOR_SUBSCRIPTION:
            return {
                ...state,
                activeTab: action.payload
            }
        case actionTypes.SUBSCRIPTION_LIST_VIEW:
            return {
                ...state,
                subscriptionView: action.payload !== "" ? action.payload : 'list'
            }
        case actionTypes.SUBSCRIPTION_DETAIL_MODAL:
            return {
                ...state,
                showDetails: !state.showDetails,
                subscriptionData: action.payload
            }
        case actionTypes.SUBSCRIPTION_PERIOD:
            return {
                ...state,
                PlanPeriod: !state.PlanPeriod,
            }
        case actionTypes.READ_MORE_USERCONTENT:
            return {
                ...state,
                showUser: !state.showUser,
            }
        case actionTypes.READ_MORE_ALLCONTENT:
            return {
                ...state,
                showAll: !state.showAll,
            }
        case actionTypes.SUBSCRIPTION_PAYABLE_DETAILS:
            return {
                ...state,
                payable_deatails: action.payload ? action.payload : {}
            }
        case actionTypes.INITIAL_SUBSCRIPTION_SELECT:
            return {
                ...state,
                selectedPackage: [...state.selectedPackage, action.payload],
                packagePlanType: action.planType
            }

        case actionTypes.SUBSCRIPTION_SELECTED_PACKAGE:
            let packageName = action.payload,
                planType = action.planType,
                selectedPackage = [...state.selectedPackage],
                packagePlanType = state.packagePlanType;

            if (selectedPackage && selectedPackage.length === 0) {
                selectedPackage = selectedPackage.includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
                packagePlanType = planType;
            } else if (selectedPackage && selectedPackage.length > 0 && !selectedPackage.includes('combo') && !selectedPackage.includes('pro') && packagePlanType === 'monthly' && planType === 'monthly') {
                selectedPackage = selectedPackage.includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
            } else if (selectedPackage && selectedPackage.length > 0 && !selectedPackage.includes('combo') && !selectedPackage.includes('pro') && packagePlanType === 'yearly' && planType === 'yearly') {
                selectedPackage = selectedPackage.includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
            } else if (selectedPackage && selectedPackage.length > 0 && !selectedPackage.includes('combo') && !selectedPackage.includes('pro') && packagePlanType === 'trial' && planType === 'trial') {
                selectedPackage = selectedPackage.includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
            }

            if (packageName === 'combo') {
                if (selectedPackage.includes('management')) {
                    selectedPackage = selectedPackage.filter(e => e !== 'management');
                }
                if (selectedPackage.includes('updates')) {
                    selectedPackage = selectedPackage.filter(e => e !== 'updates');
                }
                selectedPackage = [...selectedPackage].includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
                packagePlanType = planType;
            }
            // if (packageName === 'pro') {
            //     if (selectedPackage.includes('management')) {
            //         selectedPackage = selectedPackage.filter(e => e !== 'management');
            //     }
            //     if (selectedPackage.includes('updates')) {
            //         selectedPackage = selectedPackage.filter(e => e !== 'updates');
            //     }
            //     selectedPackage = [...selectedPackage].includes(packageName) ? [...selectedPackage] : [...selectedPackage, packageName];
            //     packagePlanType = planType;
            // }

            if (selectedPackage.includes('management') && selectedPackage.includes('updates')) {
                selectedPackage = [...selectedPackage].filter(e => e !== 'management' && e !== 'updates')
                selectedPackage = [...selectedPackage, 'combo']
            }
            // if (selectedPackage.includes('pro') ) {
            //     selectedPackage = [...selectedPackage, 'pro']
            // }
            return {
                ...state,
                selectedPackage: selectedPackage,
                packagePlanType: packagePlanType
            }

        case actionTypes.SELECT_PRINT_PACK:
            return {
                ...state,
                printingStatus: action.payload === 'printing' ? true : false
            }

        case actionTypes.CLEAR_SELECTED_SUB_PLANS:
            let printSatus, selected_Package = [...state.selectedPackage];
            localStorage.removeItem('offline_cart_subscription_details')
            localStorage.removeItem('offline_cart_employeePlan')
            localStorage.removeItem('selected_addon')
            if (action.payload === 'printing') {
                printSatus = false;
            } else {
                selected_Package = [...state.selectedPackage].includes(action.payload) ? [...state.selectedPackage].filter(e => e !== action.payload) : [...state.selectedPackage]
            }
            return {
                ...state,
                selectedPackage: selected_Package,
                printingStatus: printSatus === false ? false : state.printingStatus
            }

        case actionTypes.REMOVE_SUBSCRIPTION_SELECTED_PACKAGE:
            return {
                ...state,
                selectedPackage: [],
                planRenewalType: '',
                printingStatus: false,
            }

        // case actionTypes.INSERT_SELECTED_PRICES:
        //     return {
        //         ...state,
        //         priceIds: action.payload
        //     }

        case actionTypes.SUBSCRIPTION_SELECTED_PACKAGE_DETAILS:
            return {
                ...state,
                selectedPackageDetails: { ...action.payload }
            }
        case actionTypes.SUBSCRIPTION_PLAN_RENEWAL:
            return {
                ...state,
                planRenewalType: { ...state.planRenewalType, [action.payload.package]: action.payload.plantype }
            }
        case actionTypes.REMOVE_SUBSCRIPTION_PLAN_RENEWAL:
            return {
                ...state,
                planRenewalType: {}
            }
        case actionTypes.SELECT_ADDON_PACKAGE:
            let select_addon = [...action.payload],
                plansDetails = { ...state.planRenewalType };
            let proIndex = select_addon.includes('pro');
            let comboIndex = select_addon.includes('combo');
            let managementIndex = select_addon.includes('management');
            let updateIndex = select_addon.includes('updates');

            if (comboIndex) {
                if ((plansDetails && plansDetails.combo && plansDetails.combo === 'monthly') || (plansDetails && plansDetails.combo && plansDetails.combo === 'yearly') || (plansDetails && plansDetails.combo && plansDetails.combo === 'trial')) {
                    if (select_addon.includes('management') && select_addon.includes('updates')) {
                        plansDetails['management'] = plansDetails.combo;
                        plansDetails['updates'] = plansDetails.combo;
                        select_addon = [...select_addon].filter(e => e !== 'updates' && e !== 'management');
                    }
                    if (select_addon.includes('combo') && !select_addon.includes('management') && !select_addon.includes('updates')) {
                        plansDetails['management'] = plansDetails.combo;
                        plansDetails['updates'] = plansDetails.combo;
                    }
                    if (select_addon.includes('management') && plansDetails.management === 'yearly' || select_addon.includes('updates') && plansDetails.updates === 'yearly') {
                        plansDetails['combo'] = '';
                        select_addon = [...select_addon].filter(e => e !== 'combo');
                    }
                    if (managementIndex && !updateIndex) {
                        plansDetails['management'] = plansDetails.management;
                    }
                    if (!managementIndex && updateIndex) {
                        plansDetails['updates'] = plansDetails.updates;
                    }
                    select_addon = [...select_addon]
                }
                // else if (plansDetails && plansDetails.combo && plansDetails.combo === 'yearly') {
                //     if (select_addon.includes('management') && select_addon.includes('updates')) {
                //         plansDetails['management'] = plansDetails.combo;
                //         plansDetails['updates'] = plansDetails.combo;
                //         select_addon = [...select_addon].filter(e => e !== 'updates' && e !== 'management');
                //     }
                //     if (select_addon.includes('combo') && !select_addon.includes('management') && !select_addon.includes('updates')) {
                //         plansDetails['management'] = plansDetails.combo;
                //         plansDetails['updates'] = plansDetails.combo;
                //     }
                //     if (select_addon.includes('management') && plansDetails.management === 'monthly' || select_addon.includes('updates') && plansDetails.updates === 'monthly') {
                //         plansDetails['combo'] = '';
                //         select_addon = [...select_addon].filter(e => e !== 'combo');
                //     }
                //     if (managementIndex && !updateIndex) {
                //         plansDetails['management'] = plansDetails.management;
                //     }
                //     if (!managementIndex && updateIndex) {
                //         plansDetails['updates'] = plansDetails.updates;
                //     }
                //     select_addon = [...select_addon]
                // } else if (plansDetails && plansDetails.combo && plansDetails.combo === 'trial') {

                // }
            }
            if (proIndex) {
                if ((plansDetails && plansDetails.pro && plansDetails.pro === 'monthly') || (plansDetails && plansDetails.pro && plansDetails.pro === 'yearly') || (plansDetails && plansDetails.pro && plansDetails.pro === 'trial')) {
                    if (select_addon.includes('management') && select_addon.includes('updates')) {
                        plansDetails['management'] = plansDetails.combo;
                        plansDetails['updates'] = plansDetails.combo;
                        select_addon = [...select_addon].filter(e => e !== 'updates' && e !== 'management');
                    }
                    if (select_addon.includes('pro') && !select_addon.includes('management') && !select_addon.includes('updates')) {
                        plansDetails['management'] = plansDetails.pro;
                        plansDetails['updates'] = plansDetails.pro;
                    }
                    if (select_addon.includes('management') && plansDetails.management === 'yearly' || select_addon.includes('updates') && plansDetails.updates === 'yearly') {
                        plansDetails['pro'] = '';
                        select_addon = [...select_addon].filter(e => e !== 'pro');
                    }
                    if (managementIndex && !updateIndex) {
                        plansDetails['management'] = plansDetails.management;
                    }
                    if (!managementIndex && updateIndex) {
                        plansDetails['updates'] = plansDetails.updates;
                    }
                    select_addon = [...select_addon]
                }
                // else if (plansDetails && plansDetails.combo && plansDetails.combo === 'yearly') {
                //     if (select_addon.includes('management') && select_addon.includes('updates')) {
                //         plansDetails['management'] = plansDetails.combo;
                //         plansDetails['updates'] = plansDetails.combo;
                //         select_addon = [...select_addon].filter(e => e !== 'updates' && e !== 'management');
                //     }
                //     if (select_addon.includes('combo') && !select_addon.includes('management') && !select_addon.includes('updates')) {
                //         plansDetails['management'] = plansDetails.combo;
                //         plansDetails['updates'] = plansDetails.combo;
                //     }
                //     if (select_addon.includes('management') && plansDetails.management === 'monthly' || select_addon.includes('updates') && plansDetails.updates === 'monthly') {
                //         plansDetails['combo'] = '';
                //         select_addon = [...select_addon].filter(e => e !== 'combo');
                //     }
                //     if (managementIndex && !updateIndex) {
                //         plansDetails['management'] = plansDetails.management;
                //     }
                //     if (!managementIndex && updateIndex) {
                //         plansDetails['updates'] = plansDetails.updates;
                //     }
                //     select_addon = [...select_addon]
                // } else if (plansDetails && plansDetails.combo && plansDetails.combo === 'trial') {

                // }
            }
            return {
                ...state,
                select_addon: [...select_addon],
                planRenewalType: { ...plansDetails },
            }

        case actionTypes.ADD_NEW_SUBSCRIPTION:
            var item_exist = [...state.addedDocuments].find(item => item._id === action.payload._id);
            return {
                ...state,
                addedDocuments: item_exist ? [...state.addedDocuments] : [...state.addedDocuments, action.payload]
            }
        case actionTypes.REMOVE_SUBSCRIPTION:
            var item_exist = [...state.addedDocuments].find(item => item._id === action.payload);
            return {
                ...state,
                addedDocuments: item_exist ? [...state.addedDocuments].filter(e => e._id !== action.payload) : [...state.addedDocuments]
            }
        case actionTypes.CLEAR_ADDED_SUBSCRIPTION:
            // var item_exist = [...state.addedDocuments].find(item => item._id === action.payload);
            return {
                ...state,
                addedDocuments: []
            }
        case actionTypes.VIEW_USER_DETAILS:
            let res = action.payload, selected_addon = [...state.select_addon], planRenewalType = {};
            // if (res && res.documentManagement && res.documentManagement.status === 1) selected_addon = [...selected_addon, 'management']
            // if (res && res.documentUpdate && res.documentUpdate.status === 1) selected_addon = [...selected_addon, 'updates']
            // if (res && res.comboPackage && res.comboPackage.status === 1) selected_addon = [...selected_addon, 'combo']
            // if (res && res.documentManagement && res.documentManagement.status === 1 && res.documentManagement.planType === 'monthly') {
            //     planRenewalType['management'] = 'monthly'
            // } else if (res && res.documentManagement && res.documentManagement.status === 1 && res.documentManagement.planType === 'yearly') {
            //     planRenewalType['management'] = 'yearly'
            // }
            // if (res && res.documentUpdate && res.documentUpdate.status === 1 && res.documentUpdate.planType === 'monthly') {
            //     planRenewalType['updates'] = 'monthly'
            // } else if (res && res.documentUpdate && res.documentUpdate.status === 1 && res.documentUpdate.planType === 'yearly') {
            //     planRenewalType['updates'] = 'yearly'
            // }
            // if (res && res.comboPackage && res.comboPackage.status === 1 && res.comboPackage.planType === 'monthly') {
            //     planRenewalType['combo'] = 'monthly'
            // } else if (res && res.comboPackage && res.comboPackage.status === 1 && res.comboPackage.planType === 'yearly') {
            //     planRenewalType['combo'] = 'yearly'
            // }

            return {
                ...state,
                monthlymanagementCheck: res && res.documentManagement && res.documentManagement.status === 1 && res.documentManagement.planType === 'monthly' ? 1 : 0,
                yearlymanagementCheck: res && res.documentManagement && res.documentManagement.status === 1 && res.documentManagement.planType === 'yearly' ? 1 : 0,
                monthlyupdatesCheck: res && res.documentUpdate && res.documentUpdate.status === 1 && res.documentUpdate.planType === 'monthly' ? 1 : 0,
                yearlyupdatesCheck: res && res.documentUpdate && res.documentUpdate.status === 1 && res.documentUpdate.planType === 'yearly' ? 1 : 0,
                monthlycomboCheck: res && res.comboPackage && res.comboPackage.status === 1 && res.comboPackage.planType === 'monthly' ? 1 : 0,
                yearlycomboCheck: res && res.comboPackage && res.comboPackage.status === 1 && res.comboPackage.planType === 'yearly' ? 1 : 0,
                monthlyproCheck: res && res.proPackage && res.proPackage.status === 1 && res.proPackage.planType === 'monthly' ? 1 : 0,
                yearlyproCheck: res && res.proPackage && res.proPackage.status === 1 && res.proPackage.planType === 'yearly' ? 1 : 0,
                select_addon: [...selected_addon],
                planRenewalType: { ...planRenewalType }
            }
        case actionTypes.PACKAGE_SELECT_CHECKBOX:

            let pacArr = [...action.payload], plans = action.plan, userDetails = action.user,
                monthlymanagementCheck = 0, yearlymanagementCheck = 0, monthlyupdatesCheck = 0, yearlyupdatesCheck = 0, monthlycomboCheck = 0, yearlycomboCheck = 0, yearlyproCheck = 0, monthlyproCheck = 0;

            if (pacArr && pacArr.length > 0 && pacArr.includes('management') && plans.management === 'monthly' || pacArr.includes('combo') && plans.combo === 'monthly' || pacArr.includes('pro') && plans.pro === 'monthly') {
                monthlymanagementCheck = 1;
                yearlymanagementCheck = 0;
            } else if (pacArr && pacArr.length > 0 && pacArr.includes('management') && plans.management === 'yearly' || pacArr.includes('combo') && plans.combo === 'yearly' || pacArr.includes('pro') && plans.pro === 'yearly') {
                monthlymanagementCheck = 0;
                yearlymanagementCheck = 1;
            } else {
                monthlymanagementCheck = 0;
                yearlymanagementCheck = 0;
            }
            if (pacArr && pacArr.length > 0 && pacArr.includes('updates') && plans.updates === 'monthly' || pacArr.includes('combo') && plans.combo === 'monthly' || pacArr.includes('pro') && plans.pro === 'monthly') {
                monthlyupdatesCheck = 1;
                yearlyupdatesCheck = 0;
            } else if (pacArr && pacArr.length > 0 && pacArr.includes('updates') && plans.updates === 'yearly' || pacArr.includes('combo') && plans.combo === 'yearly' || pacArr.includes('pro') && plans.pro === 'yearly') {
                yearlyupdatesCheck = 1;
                monthlyupdatesCheck = 0
            } else {
                monthlyupdatesCheck = 0;
                yearlyupdatesCheck = 0;
            }

            if (pacArr && pacArr.length > 0 && pacArr.includes('combo') && plans.combo === 'monthly') {
                monthlycomboCheck = 1;
                yearlycomboCheck = 0;
            } else if (pacArr && pacArr.length > 0 && pacArr.includes('combo') && plans.combo === 'yearly') {
                yearlycomboCheck = 1;
                monthlycomboCheck = 0;
            } else {
                monthlycomboCheck = 0;
                yearlycomboCheck = 0;
            }
            if (pacArr && pacArr.length > 0 && pacArr.includes('pro') && plans.pro === 'monthly') {
                monthlyproCheck = 1;
                yearlyproCheck = 0;
            } else if (pacArr && pacArr.length > 0 && pacArr.includes('pro') && plans.pro === 'yearly') {
                yearlyproCheck = 1;
                monthlyproCheck = 0;
            } else {
                monthlyproCheck = 0;
                yearlyproCheck = 0;
            }
            return {
                ...state,
                monthlymanagementCheck: userDetails && userDetails.documentManagement && userDetails.documentManagement.status === 1 && userDetails.documentManagement.planType === 'monthly' ? 1 : monthlymanagementCheck,
                yearlymanagementCheck: userDetails && userDetails.documentManagement && userDetails.documentManagement.status === 1 && userDetails.documentManagement.planType === 'yearly' ? 1 : yearlymanagementCheck,
                monthlyupdatesCheck: userDetails && userDetails.documentUpdate && userDetails.documentUpdate.status === 1 && userDetails.documentUpdate.planType === 'monthly' ? 1 : monthlyupdatesCheck,
                yearlyupdatesCheck: userDetails && userDetails.documentUpdate && userDetails.documentUpdate.status === 1 && userDetails.documentUpdate.planType === 'yearly' ? 1 : yearlyupdatesCheck,
                monthlycomboCheck: monthlycomboCheck,
                yearlycomboCheck: yearlycomboCheck,
                monthlyproCheck: monthlyproCheck,
                yearlyproCheck: yearlyproCheck
            }

        case actionTypes.COUNTRY_LIST:
            return {
                ...state,
                countryList: action.payload,
            }
        case actionTypes.SUBSCRIPTION_TYPE_LIST:
            return {
                ...state,
                subscriptionTypeList: action.payload,
            }
        case actionTypes.SUBSCRIPTION_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload,
            }
        case actionTypes.SHOW_NO_DATA:
            return {
                ...state,
                showNoData: action.payload,
            }
        case actionTypes.SUBSCRIPTION_PRICE_DETAILS:
            return {
                ...state,
                priceDetails: action.payload ? action.payload : {},
            }
        case actionTypes.GET_USER_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptionDatas: action.payload
            }
        case actionTypes.GET_USER_INVOICE_LIST:
            return {
                ...state,
                invoiceList: action.payload,
                invoiceListCount: action.total
            }
        case actionTypes.GET_USER_INVOICE_DETAILS:
            return {
                ...state,
                invoiceDetails: action.payload
            }
        case actionTypes.CAN_UPDATE_SUBSCRIPTION:
            return {
                ...state,
                canUpdateSubscription: action.payload
            }
        case actionTypes.FILTER_SUBSCRIPTION:
            return {
                ...state,
                filterValues: action.payload
            }
        case actionTypes.INVOICE_PAGINATION:
            return {
                ...state,
                invoiceActivePage: action.payload
            }
        case actionTypes.EMPLOYEE_SELECT_PLAN:
            return {
                ...state,
                selectedEmpPlans: action.payload
            }
        case actionTypes.FILE_DETAILS_PAGE:
            return {
                ...state,
                viewDocument: action.payload
            }
        case actionTypes.SELECTED_DOCUMENT_SUBSCRIPTION_PLAN:
            return {
                ...state,
                selectedDocPlans: action.payload
            }
        case actionTypes.SELECTED_EMPLOYEE_SUBSCRIPTION_PLAN:
            return {
                ...state,
                selectedEmployeePlans: action.payload
            }
        case actionTypes.SUBSCRIPTION_PLAN_PERIOD:
            return {
                ...state,
                subscriptionPlan: action.payload
            }
        case actionTypes.SHOW_PLAN_UPDATE_MODAL:
            return {
                ...state,
                showPlanModal: !state.showPlanModal,
                updatePlanType: action.payload
            }
        case actionTypes.SHOW_SUBSCRIPTION_CANCEL_MODAL:
            return {
                ...state,
                showCancelsubscription: !state.showCancelsubscription,
            }
        case actionTypes.STORAGE_DETAILS:
            return {
                ...state,
                storageData: action.payload
            }
        case actionTypes.DOCUMENT_SUBSCRIPTION_SELECTION:
            return {
                ...state,
                selectedPackage: [action.payload],
                packagePlanType: action.planType
            }
        case actionTypes.DOCUMENT_LICENCE_TYPE:
            return {
                ...state,
                licenceType: action.payload,
            }

        default:
            return state;
    }
};