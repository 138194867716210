import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import App from './modules/App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import "./assets/css/new-responsive.css";
import { HelmetProvider } from 'react-helmet-async';


const store = createStore(rootReducer, {}, applyMiddleware(thunk));
// const store = configureStore({
// 	reducer: rootReducer,
// 	// You can add middleware or enhancers if needed
// 	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(), // Add custom middleware if required
//   });
ReactDOM.render(
	<Provider store={store}>
		<HelmetProvider>
			<App />
		</HelmetProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
